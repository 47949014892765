import "./styles.css";
import { keccak256 } from "ethers/lib/utils";
import { ethers } from "ethers";
import {
  FlashbotsBundleConflictType as ConflictType,
  FlashbotsBundleProvider
} from "./flashbots";
import _ from "lodash";

const hexToInt = (obj) => {
  if (typeof obj !== "object" || !obj) return obj;
  if (obj["type"] === "BigNumber") {
    return new ethers.BigNumber(obj);
  }
  if (obj instanceof ethers.BigNumber) {
    return obj.toString();
  }
  try {
    return Object.assign(
      new obj.constructor(),
      Object.fromEntries(Object.entries(obj).map(([k, v]) => [k, hexToInt(v)]))
    );
  } catch (er) {
    return obj;
  }
};

const isAuthorized = !!localStorage.flashbotsPrivateKey;
let authSigner = ethers.Wallet.createRandom();
if (isAuthorized) {
  authSigner = new ethers.Wallet(localStorage.flashbotsPrivateKey);
}

const run = async () => {
  const provider = new ethers.providers.JsonRpcProvider(
    { url: "https://mainnet.infura.io/v3/92731c5a936c4d099a8ab6025a3bbd9d" },
    1
  );
  const flashbotsProvider = await FlashbotsBundleProvider.create(
    provider, // a normal ethers.js provider, to perform gas estimiations and nonce lookups
    authSigner, // ethers.js signer wallet, only for signing request payloads, not transactions
    "https://proxy.splitpage.app/https://relay.flashbots.net"
  );
  const logs = [];
  const log = (log, ...args) => {
    logs.push([log, ...args]);
  };

  let params = new URLSearchParams(window.location.search);
  let signed_tx = params.get("signed_tx");
  let bundle_url = params.get("bundle_url");
  console.log("bundle_url", bundle_url);
  let signed_txs = params.get("signed_txs");
  console.log({ signed_txs });
  let signed_tx_2 = params.get("signed_tx_2");
  let tx = params.get("tx") || params.get("txn");
  let bn = params.get("block");
  if (bn && !signed_tx && params.get("tx")) {
    signed_tx = params.get("tx");
  }
  let pools = params.get("pools");
  let tokens = params.get("tokens");
  window.provider = provider;
  if (tx) {
    // redirect route
    tx = await provider.getTransaction(tx);
    const bn = tx.blockNumber;
    const url1 = `https://etherscan.io/tx/${tx.hash}`;
    const url2 = `https://flashbots-explorer.marto.lol/?block=${bn}`;
    const url3 = `https://splitpage.app/?left=${encodeURIComponent(
      url1
    )}&right=${encodeURIComponent(url2)}`;
    console.log("iframe is", url3);
    document.body.innerHTML = `<iframe style="width: 100%; height: 100%;" src=${url3}/>`;
    return;
  }
  log("On block", bn, `https://flashbots-explorer.marto.lol/?block=${bn}`);
  const checkMempoolConflicts = async () => {
    log("Scanning block for possible non-flashbots conflicts...");
    console.log(pools, tokens);
    if (pools) {
      const parsedPools = _.compact(pools.split("0x"));
      const parsedTokens = _.compact(tokens.split("0x")).map((t) => "0x" + t);
      const bn_hex = "0x" + Number(bn).toString(16);
      var filter = {
        fromBlock: bn_hex,
        toBlock: bn_hex,
        address: parsedTokens,
        topics: [
          "0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef", // ERC 20 transfer
          null,
          parsedPools.map((p) => "0x000000000000000000000000" + p)
        ]
      };
      var filter2 = {
        fromBlock: bn_hex,
        toBlock: bn_hex,
        address: parsedTokens,
        topics: [
          "0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef", // ERC 20 transfer
          parsedPools.map((p) => "0x000000000000000000000000" + p),
          null
        ]
      };
      console.log(filter);
      window.ff = filter;
      // https://github.com/ethers-io/ethers.js/issues/473
      const txs = await provider.send("eth_getLogs", [filter]);
      const txs2 = await provider.send("eth_getLogs", [filter2]);

      const conflicts = [];
      const seenTxns = new Set();
      for (const t of txs.concat(txs2)) {
        const txn = hexToInt(await provider.getTransaction(t.transactionHash));
        if (seenTxns.has(t.transactionHash)) continue;
        seenTxns.add(t.transactionHash);
        conflicts.push({
          hash: txn.hash,
          from: txn.from,
          to: txn.to,
          transacitonIndex: txn.transactionIndex,
          gasPrice: txn.gasPrice
        });
      }
      log("Txns that touch the same pools & tokens:", conflicts);
    } else {
      log(
        "Cannot check for non-flashbots conflicts; provide &pools=0x... in url."
      );
    }
  };

  const checkRequest = async (rpcRequest) => {
    try {
      await checkMempoolConflicts();
      const blocks = await flashbotsProvider.fetchBlocksApi(bn);
      // log(blocks["blocks"]);
      let blockGwei = 1;
      let blockReward = 1;
      let lbGwei = 1;
      let numBundles = 0;
      let lbReward = 1;
      if (blocks?.["blocks"]?.[0]?.transactions?.length) {
        console.log(blocks);
        blockGwei = Number(blocks["blocks"][0]["gas_price"]) / 1e9;
        blockReward = Number(blocks["blocks"][0]["miner_reward"]) / 1e18;
        const bundles = _.assign(
          [],
          _.groupBy(blocks["blocks"][0].transactions, "bundle_index")
        );
        numBundles = bundles.length;
        const lb = _.last(bundles);
        lbReward = _.sum(_.map(lb, (t) => Number(t.total_miner_reward)));
        lbGwei = lbReward / _.sum(_.map(lb, (b) => b.gas_used));
        lbReward /= 1e18;
        lbGwei /= 1e9;
      }
      log({ blockGwei, blockReward, numBundles });
      const bundle = rpcRequest.params[0];

      const blockNumber = Number(bundle.blockNumber);

      const prevBlockTimestamp = new Date(
        (await provider.getBlock(blockNumber)).timestamp * 1000
      ).toISOString();
      const nextBlockTimestamp = new Date(
        (await provider.getBlock(blockNumber + 1)).timestamp * 1000
      ).toISOString();

      const signedTransactions = await flashbotsProvider.signBundle(
        bundle.txs.map((signedTransaction) => ({ signedTransaction }))
      );
      const conflicts = hexToInt(
        await flashbotsProvider.getConflictingBundle(
          signedTransactions,
          blockNumber
        )
      );
      const ourGwei =
        Number(conflicts.initialSimulation.results[0].gasPrice) / 1e9;
      const ourReward =
        Number(conflicts.initialSimulation.results[0].coinbaseDiff) / 1e18;
      const ourGweiRatio = ourGwei / blockGwei;
      const ourRewardRatio = ourReward / blockReward;
      const lbGweiRatio = lbGwei / blockGwei;
      const lbRewardRatio = lbReward / blockReward;

      console.log(conflicts);
      const pricingComparison =
        conflicts.conflictingBundleGasPricing &&
        Object.fromEntries(
          Object.keys(conflicts.targetBundleGasPricing).map((key) => {
            const ours = conflicts.targetBundleGasPricing[key];
            const them = conflicts.conflictingBundleGasPricing[key];
            const diff =
              ours instanceof ethers.BigNumber ? ours.sub(them) : ours - them;

            return [key, { ours, them, diff }];
          })
        );

      let sentToMinersAt = 0;
      if (isAuthorized) {
        const bundleHash = FlashbotsBundleProvider.generateBundleHash(
          bundle.txs.map(keccak256)
        );
        log({ bundleHash });
        const bundleStats = await flashbotsProvider.getBundleStats(
          bundleHash,
          blockNumber
        );
        log(bundleStats);
        console.log(bundleStats);
        if (bundleStats?.["sentToMinersAt"]) {
          sentToMinersAt = Date.parse(bundleStats["sentToMinersAt"]);
        }
      }
      // log("Conflict type:", ConflictType[conflicts.conflictType]);
      if (process.env.VERBOSE) log(conflicts);
      // if (pricingComparison) {
      //   log(pricingComparison);
      // }
      if (conflicts.conflictType === ConflictType.NonceCollision) {
        log(
          "[KNOWN] Nonce collision! (either self-collision or backrun collision)"
        );
      }
      const conflictingBundle =
        conflicts.conflictingBundle[conflicts.conflictingBundle.length - 1];
      if (conflicts.conflictType === ConflictType.NoBundlesInBlock) {
        log("[KNOWN] Not a flashbot block");
      } else if (
        conflictingBundle &&
        pricingComparison &&
        pricingComparison.effectivePriorityFeeToMiner.diff <
          ethers.BigNumber.from(0)
      ) {
        log(
          "[KNOWN] Lost on fees to",
          conflictingBundle.to_address,
          "tx",
          conflictingBundle.transaction_hash
        );
        log(
          "Gas Ratio",
          conflictingBundle.gas_used / conflicts.initialSimulation.totalGasUsed
        );
      } else if (
        conflictingBundle &&
        conflictingBundle.bundle_type === "rogue"
      ) {
        log(
          "[KNOWN] Rogue",
          conflictingBundle.to_address,
          "tx",
          conflictingBundle.transaction_hash
        );
      } else if (Date.parse(nextBlockTimestamp) - sentToMinersAt < 1000) {
        log("[KNOWN] Sent to miners too late");
      } else if (conflicts.conflictType === ConflictType.NoConflict) {
        log("[UNKNOWN] No Conflict! Maybe lost on timing?");
      } else if (conflictingBundle) {
        log(
          "[UNKNOWN] Investigate me! Lost on to",
          conflictingBundle.to_address,
          "tx",
          conflictingBundle.transaction_hash
        );
        log(
          "Gas Ratio",
          conflictingBundle.gas_used / conflicts.initialSimulation.totalGasUsed
        );
        log("[UNKNOWN] Maybe lost on timing?");
      } else if (conflicts.initialSimulation.firstRevert) {
        log("[KNOWN] Txn reverted");
      } else {
        log("[UNKNOWN] Investigate me! No conflicting bundles.");
        log("[UNKNOWN] Maybe lost on timing?");
      }
      log("pricing comparison", hexToInt(pricingComparison));
      log(conflicts);

      log({
        minGweiRatio: lbGweiRatio,
        minRewardRatio: lbRewardRatio,
        minGwei: lbGwei,
        minReward: lbReward
      });
      log({ ourGweiRatio, ourRewardRatio, ourGwei, ourReward });
      log(rpcRequest);
    } catch (error) {
      console.error(error);
      log("[ERROR] Something went wrong");
      log(error.toString());
      await checkMempoolConflicts();
      return;
    }
  };
  if ((signed_tx || signed_txs) && bn) {
    const rpcRequest = {
      jsonrpc: "2.0",
      id: 1,
      method: "eth_callBundle",
      params: [
        {
          txs: signed_txs
            ? signed_txs.split(",")
            : signed_tx_2
            ? [signed_tx, signed_tx_2]
            : [signed_tx],
          blockNumber: bn
        }
      ]
    };
    console.log(rpcRequest);
    await checkRequest(rpcRequest);
  } else if (bundle_url) {
    const r = await fetch(bundle_url);
    const bundle = await r.json();
    const rpcRequest = {
      jsonrpc: "2.0",
      id: 1,
      method: "eth_callBundle",
      params: [bundle]
    };
    console.log(rpcRequest);
    await checkRequest(rpcRequest);
  } else {
    log(
      "unknown args. provide ?tx=..., ?txn=..., or ?signed_txn=...&bn=..., or paste bundle below"
    );
    const inputElt = document.querySelector("#bundleTextArea");
    const submitElt = document.querySelector("#bundleSubmit");
    inputElt.hidden = false;
    submitElt.hidden = false;
    submitElt.addEventListener("click", () => {
      const rpcRequest = {
        jsonrpc: "2.0",
        id: 1,
        method: "eth_callBundle",
        params: [JSON.parse(inputElt.value)]
      };
      checkRequest(rpcRequest);
    });
  }

  // === get user stats ==
  if (isAuthorized) {
    log("found localStorage.flashbotsPrivateKey. getting user stats...");
    log(await flashbotsProvider.getUserStats());
  } else {
    log(
      "set localStorage.flashbotsPrivateKey to get timing and signer flashbots user stats"
    );
  }
  // ==========
  console.log(logs);
  document.querySelector("#logsPre").innerHTML = JSON.stringify(logs, null, 2);
};
setTimeout(() => {
  run();
}, 10);
